declare const process: {
  env: {
    [key: string]: string;
  };
};

// need to check APPLICATION_SOURCE
export const APPLICATION_SOURCE =
  process.env.REACT_APP_APPLICATION_SOURCE || 'mobile';
export const API_URL: string = process.env.REACT_APP_API_URL;
export const APP_URL: string = process.env.REACT_APP_URL;
export const HOME_PAGE_URL: string = process.env.REACT_APP_HOME_PAGE_URL;
export const IS_DEBUG_MODE = Boolean(process.env.REACT_APP_DEBUG);
export const SHOPIFY_STORE_URL = process.env.REACT_APP_SHOPIFY_STORE_URL;
export const INTERCOM_ID = process.env.REACT_APP_INTERCOM_ID;

export const ASSETS_CDN_URL = 'assets.withminta.com';

export const SENTRY_DNS = process.env.REACT_APP_SENTRY_DNS;
export const FB_APP_ID = process.env.REACT_APP_FB_APP_ID;
export const MESSENGER_APP_ID = process.env.REACT_APP_MESSENGER_APP_ID;
export const FB_PAGE_ID = process.env.REACT_APP_FB_PAGE_ID;
export const VIDEO_FETCH_LIMIT = 8;
export const VIDEO_MESSAGE_FETCH_LIMIT = 8;
export const PRODUCT_FETCH_LIMIT = 10;
export const INITIAL_VIDEO_FETCH_DELAY = 2500;
export const API_REQ_RETRY_DELAY = 3000;
export const SESSION_STORAGE_REDIRECT_AFTER_SIGNIN = 'redirectAfterSignin';
export const SESSION_STORAGE_REDIRECT_AFTER_PAYMENT = 'redirectAfterPayment';
export const SHOPIFY_APP_CLIENT_ID =
  process.env.REACT_APP_SHOPIFY_APP_CLIENT_ID;
