import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from 'serviceWorker';
import * as Sentry from '@sentry/browser';
import { SENTRY_DNS } from 'constants/config';

import { version } from '../package.json';

// sentry dev
Sentry.init({
  release: version,
  dsn: SENTRY_DNS,
});

const importBuildTarget = () => {
  if (process.env.REACT_APP_BUILD_TARGET === 'player') {
    return import('EmbeddedPlayer');
  }
  // default app
  return import('MintaApp');
  // return Promise.reject(
  //   new Error(`No such build target: ${process.env.REACT_APP_BUILD_TARGET}`)
  // );
};

// Import the entry point and render it's default export
importBuildTarget().then(({ default: Environment }) =>
  // eslint-disable-next-line react/no-render-return-value
  ReactDOM.render(<Environment />, document.getElementById('root'))
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
